// Here you can add other styles
.app-footer {
    flex: 0 0 80px;
    justify-content: space-between;
    height: 80px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
}

.app-body {
    padding-bottom: 80px;
}

.app-footer h5 {
    margin: 0;
    padding: 0;
}
